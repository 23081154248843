import React from 'react'
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout'
// import CssBaseline from '@mui/material/CssBaseline'
// import StyledEngineProvider from '@mui/material/StyledEngineProvider'

export default function TopLayout({ children, theme }) {
  return (
    // <StyledEngineProvider injectFirst>
      <ThemeTopLayout theme={theme}>
        {/* <CssBaseline /> */}
        {children}
      </ThemeTopLayout>
    // </StyledEngineProvider>
  )
}