import { responsiveFontSizes, createTheme } from '@mui/material'

const theme = responsiveFontSizes(createTheme({
  palette: {
    // main: '#c1a338',
    // paragraphs: '#375FD4',
    // titles: '#551A8B',
    // icons: '#717171',
    // text: {
    //   primary: '#fff',
    //   cardTitle: '#222222',
    //   cardText: '#717171'
    // }
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#fff',
      light2: '#98cbfa', // this is my change, the rest are the out-of-the-box
    },
  },
  typography: {
    fontFamily: [
      'Mooli',
      'Montserrat',
      'Dancing Script',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    }
  },
}))

export default theme